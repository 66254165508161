import React from 'react';
import { Flex, Input, Button, NumberInput, NumberInputField, Badge } from '@chakra-ui/react';

interface PaginationProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  totalRecords: number;
  handlePageChange: (valueAsString: string, valueAsNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  search,
  setSearch,
  currentPage,
  setCurrentPage,
  totalPages,
  totalRecords,
  handlePageChange,
}) => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      mb={4}
      alignItems={{ base: 'stretch', md: 'center' }}
    >
      <Flex mb={{ base: 2, md: 0 }} mr={{ base: 0, md: 4 }} flex="1">
        <Input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by name"
          mr={{ base: 0, md: 4 }}
          mb={{ base: 2, md: 0 }}
          flex="1"
        />
      </Flex>
      <Flex mb={{ base: 2, md: 0 }} flexWrap="wrap" justifyContent="center">
        <Button
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        >
          First
        </Button>
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage <= 1}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        >
          Previous
        </Button>
        <NumberInput
          maxW="100px"
          value={currentPage}
          onChange={handlePageChange}
          min={1}
          max={totalPages}
          keepWithinRange={true}
          clampValueOnBlur={false}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        >
          <NumberInputField />
        </NumberInput>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= totalPages}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        >
          Next
        </Button>
        <Button
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
          mr={{ base: 0, md: 2 }}
          mb={{ base: 2, md: 0 }}
        >
          Last
        </Button>
      </Flex>
      <Flex alignItems="center" ml={{ base: 0, md: 4 }} justifyContent={{ base: 'center', md: 'flex-start' }} mt={{ base: 2, md: 0 }}>
        <Badge colorScheme="green">Total: {totalRecords}</Badge>
      </Flex>
    </Flex>
  );
};

export default Pagination;
