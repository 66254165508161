import axios from 'axios';
import { BASE_API_URL } from '../config/config';

// Create an instance of axios with a custom config
const API = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to inject the token into headers of all requests
API.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');  // Retrieve the token from local storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;  // Set Authorization header
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  response => response,  // Any status code that lie within the range of 2xx cause this function to trigger
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.error('API call failed. ', error.response.status);
    if(error.response.status === 401) {
      // If token is expired, logout user
      localStorage.removeItem('token'); 
      localStorage.removeItem('userId');
      localStorage.removeItem('fullName');
      localStorage.removeItem('r');
      window.location.href = '/agst-login';
    }
    return Promise.reject(error);
  }
);

export default API;
