// src/services/contactService.ts

import API from '../utils/api';

const fetchUsers = async (page: number, searchQuery: string = ""): Promise<any> => {
    try {
        console.log(page, "page");
        
        const response = await API.get<any>(`/api/users/list?page=${page}&search=${searchQuery}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch contacts';
        throw new Error(errorMessage);
    }
};

const fetchUsersPublic = async (page: number, searchQuery: string = ""): Promise<any> => {
    try {
        console.log(page, "page");
        
        const response = await API.get<any>(`/api/users/public?page=${page}&search=${searchQuery}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch user';
        throw new Error(errorMessage);
    }
};

const createUser = async (formData: FormData) => {
    try {
        const response = await API.post('/api/users', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to create user';
        throw new Error(errorMessage);
    }
};

const updateUser = async (id: string, formData: FormData): Promise<any> => {
    try {
        const response = await API.post(`/api/users/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to update user';
        throw new Error(errorMessage);
    }
};

const getUser = async (id: string): Promise<any> => {
    try {
        const response = await API.get(`/api/users/${id}`, {
            headers: {
                'Content-Type': 'application/json', // Change content type if needed
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to get user';
        throw new Error(errorMessage);
    }
};

const deleteUser = async (id: number): Promise<void> => {
    try {
        await API.delete(`/api/users/${id}`);
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to delete user';
        throw new Error(errorMessage);
    }
};


export const usersService = {
    fetchUsers,
    createUser,
    updateUser,
    deleteUser,
    getUser,
    fetchUsersPublic
};
