import { Container } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface AuthLayoutProps {
    children?: ReactNode; // Children can be optional
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
    console.log(children);
    
    return <Container p={4}>{children}</Container>;
};

export default AuthLayout;