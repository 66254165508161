import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Grid,
    GridItem,
    useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { categoryService } from '../../services/categoryService';
import { useAppContext } from '../../context/AppContext';
import { AddIcon } from '@chakra-ui/icons';
import { Contact } from '../interfaces/Contact';
import Select from 'react-select';
import { BASE_API_URL } from '../../config/config';
import { usersService } from '../../services/userService';

interface ContactFormProps { }

const initState = {};

const UserForm: React.FC<ContactFormProps> = () => {
    const { onContactFormOpen, onContactFormClose } = useAppContext();
    const { dispatch, state } = useAppContext();
    const { user, isContactFormOpen, isUpdate } = state;
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [categories, setCategories] = useState<any[]>([]);
    const [subCategories, setSubCategories] = useState<any[]>([]);
    const [subSubCategories, setSubSubCategories] = useState<any[]>([]);

    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(null);
    const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<any>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const toast = useToast();

    const isAuthenticated = localStorage.getItem('token');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value, type, files } = e.target as HTMLInputElement;
        setIsDisabled(false);
        if (type === "file") {
            console.log(files, "files");
            if (files && files[0]) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result as string);
                };
                reader.readAsDataURL(files[0]);
                dispatch({ type: 'SET_USER', payload: { ...user, profile_picture: files[0] } });
            }
        } else {
            dispatch({ type: 'SET_USER', payload: { ...user, [name]: value } });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(!selectedCategory) {
            toast({
                title: `Complete the Form`,
                description: `Please select the Top Level Category`,
                status: "warning",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        setIsDisabled(true);
        const formData = new FormData();

        Object.entries(user).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                formData.append(key, value instanceof Blob ? value : String(value));
            }
        });

        // Append category IDs
        if (selectedCategory) formData.append('category_ids[]', selectedCategory.value);
        if (selectedSubCategory) formData.append('category_ids[]', selectedSubCategory.value);
        if (selectedSubSubCategory) formData.append('category_ids[]', selectedSubSubCategory.value);

        formData.append('role', "user");

        try {
            let response: Contact;
            if (isUpdate) {
                formData.append('_method', 'PUT');

                await usersService.updateUser(user.id, formData);
                const response = await usersService.fetchUsers(1);
                dispatch({ type: 'SET_USERS', payload: response.data });
            } else {
                response = await usersService.createUser(formData);
                dispatch({ type: 'SET_USERS', payload: [...state.users, response] });
            }

            toast({
                title: `Contact ${isUpdate ? 'Updated' : 'Created'} Successfully`,
                description: `You have successfully ${isUpdate ? 'updated' : 'created'} a contact.`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            dispatch({ type: 'SET_CONTACT', payload: initState });
            onContactFormClose();
            setIsDisabled(false);
        } catch (error) {
            console.error('Error creating contact:', error);
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await categoryService.fetchCategories();

                setCategories(response.map(cat => ({ value: cat.id, label: cat.name })));
            } catch (error) {
                console.log('Error fetching categories', error);
            }
        };

        fetchCategories();
    }, []);


    const handleCategoryChange = (option: any | null) => {
        setSelectedCategory(option);
        setSelectedSubCategory(null);
        setSelectedSubSubCategory(null);
        setSubCategories([]);
        setSubSubCategories([]);
        if (option) {
            fetchSubCategories(option.value, 2);
        }
    };

    const handleSubCategoryChange = (option: any | null) => {
        setSelectedSubCategory(option);
        setSelectedSubSubCategory(null);
        setSubSubCategories([]);
        if (option) {
            fetchSubCategories(option.value, 3);
        }
    };

    const handleSubSubCategoryChange = (option: any | null) => {
        setSelectedSubSubCategory(option);
    };

    const fetchSubCategories = async (parentCategoryId: string, level: number) => {
        try {
            const response = await categoryService.fetchSubCategories(parentCategoryId);

            if (level === 2) setSubCategories(response.map(cat => ({ value: cat.id, label: cat.name })));
            if (level === 3) setSubSubCategories(response.map(cat => ({ value: cat.id, label: cat.name })));
        } catch (error) {
            console.log('Error fetching categories', error);
        }
    };

    const openForm = () => {
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        setSelectedSubSubCategory(null);
        dispatch({ type: 'SET_USER', payload: initState });
        onContactFormOpen()
    }

    useEffect(() => {
        if (isUpdate) {
            // Reset categories if the contact does not have them or the array is empty
            if (!user.categories || user.categories.length === 0) {
                setSelectedCategory(null);
                setSelectedSubCategory(null);
                setSelectedSubSubCategory(null);
                return;
            }

            // Set the primary category if it exists
            if (user.categories[0]) {
                const primaryCategoryOption = {
                    value: user.categories[0].id,
                    label: user.categories[0].name
                };
                setSelectedCategory(primaryCategoryOption);
                fetchSubCategories(user.categories[0].id, 2);
            } else {
                setSelectedCategory(null);
            }

            // Set the sub category if it exists
            setSelectedSubCategory(user.categories.length > 1 ? {
                value: user.categories[1].id,
                label: user.categories[1].name
            } : null);

            // Set the sub-sub category if it exists
            setSelectedSubSubCategory(user.categories.length > 2 ? {
                value: user.categories[2].id,
                label: user.categories[2].name
            } : null);


            setImagePreview(`${BASE_API_URL}/storage/app/public/${user?.profile_picture}`);
        }
    }, [user]);

    const mobileNumberLimit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length >= 10) {
            e.preventDefault();
        }
    };

    return (
        <>
            {isAuthenticated && (
                <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={() => openForm()}>
                    New User
                </Button>
            )}
            <Drawer
                isOpen={isContactFormOpen}
                placement='right'
                onClose={onContactFormClose}
                size="xl"
            >
                <DrawerOverlay />
                <form onSubmit={handleSubmit}>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            {isUpdate ? 'Update User' : 'Create User'}
                        </DrawerHeader>

                        <DrawerBody>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                                <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="category">Top Level Category</FormLabel>
                                        <Select
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            options={categories}
                                            placeholder="Select Top Category"
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem colSpan={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="subCategory">2nd Level Category</FormLabel>
                                        <Select
                                            value={selectedSubCategory}
                                            onChange={handleSubCategoryChange}
                                            options={subCategories}
                                            placeholder="Select 2nd level Category"
                                            isDisabled={subCategories.length === 0 ? true : false}
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem colSpan={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="subSubCategory">3rd Level Category</FormLabel>
                                        <Select
                                            value={selectedSubSubCategory}
                                            onChange={handleSubSubCategoryChange}
                                            options={subSubCategories}
                                            placeholder="Select 3rd level Category"
                                            isDisabled={subSubCategories.length === 0 ? true : false}
                                        />
                                    </FormControl>
                                </GridItem>
                            </Grid>

                            <Grid templateColumns="repeat(5, 1fr)" gap={6} mt={4}>
                                {/* <GridItem colSpan={1}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="title">Title</FormLabel>
                                        <ChakraSelect id="title" name="title" value={contact?.title} onChange={handleChange}>
                                            <option>Select Title</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Dr">Dr</option>
                                        </ChakraSelect>
                                    </FormControl>
                                </GridItem> */}
                                <GridItem colSpan={5}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="full_name">Full Name</FormLabel>
                                        <Input id="full_name" type="text" name="full_name" value={user?.full_name} onChange={handleChange} />
                                        <Input id="role" type="hidden" name="role" value="user" />
                                    </FormControl>
                                </GridItem>
                            </Grid>
                            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={4}>
                                {/* <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="designation">Designation</FormLabel>
                                        <Input required id="designation" type="text" name="designation" value={contact?.designation} onChange={handleChange} />
                                    </FormControl>
                                </GridItem> */}
                                {/* <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="company">Company</FormLabel>
                                        <Input id="company" type="text" name="company" value={contact?.company} onChange={handleChange} />
                                    </FormControl>
                                </GridItem> */}
                                <GridItem colSpan={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="phone_no">Phone no</FormLabel>
                                        <Input id="phone_no" type="number" name="phone_no" onKeyPress={mobileNumberLimit} value={user?.phone_no} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                {/* <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="mobile_no_02">Mobile No 02</FormLabel>
                                        <Input id="mobile_no_02" type="number" name="mobile_no_02" onKeyPress={mobileNumberLimit} value={contact?.mobile_no_02} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <FormControl>
                                        <FormLabel htmlFor="whatsapp_no">WhatsApp No</FormLabel>
                                        <Input id="whatsapp_no" type="number" name="whatsapp_no" onKeyPress={mobileNumberLimit} value={contact?.whatsapp_no} onChange={handleChange} />
                                    </FormControl>
                                </GridItem> */}
                                {/* <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="office_no_01">Office No 01</FormLabel>
                                        <Input id="office_no_01" type="number" name="office_no_01" onKeyPress={mobileNumberLimit} value={contact?.office_no_01} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="office_no_02">Office No 02</FormLabel>
                                        <Input id="office_no_02" type="number" name="office_no_02" onKeyPress={mobileNumberLimit} value={contact?.office_no_02} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="fax_no_01">Fax No 01</FormLabel>
                                        <Input id="fax_no_01" type="number" name="fax_no_01" onKeyPress={mobileNumberLimit} value={contact?.fax_no_01} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="fax_no_02">Fax No 02</FormLabel>
                                        <Input id="fax_no_02" type="number" name="fax_no_02" onKeyPress={mobileNumberLimit} value={contact?.fax_no_02} onChange={handleChange} />
                                    </FormControl>
                                </GridItem> */}
                                <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Input id="email" type="email" name="email" value={user?.email} onChange={handleChange} />
                                    </FormControl>
                                </GridItem>
                                {/* <GridItem colSpan={2}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="official_email">Official Email</FormLabel>
                                        <Input id="official_email" type="email" name="official_email" value={contact?.official_email} onChange={handleChange} />
                                    </FormControl>
                                </GridItem> */}
          
                                {!isUpdate &&
                                    <GridItem colSpan={2}>
                                        <FormControl isRequired>
                                            <FormLabel htmlFor="contact_type">Password</FormLabel>
                                            <Input id="password" type="password" name="password" value={user?.password} onChange={handleChange} />
                                        </FormControl>
                                    </GridItem>
                                }       
                                {/* <GridItem colSpan={1}>
                                    <FormControl isRequired>
                                        <FormLabel htmlFor="contact_status">Password</FormLabel>
                                        <ChakraSelect id="contact_status" name="contact_status" value={contact?.contact_status} onChange={handleChange}>
                                            <option value="On Duty">On Duty</option>
                                            <option value="Retired">Retired</option>
                                        </ChakraSelect>
                                    </FormControl>
                                </GridItem> */}
                                {/* <GridItem colSpan={1}>
                                    <FormControl>
                                        <FormLabel htmlFor="profile_picture">Profile Picture</FormLabel>
                                        <Input id="profile_picture" type="file" name="profile_picture" onChange={handleChange} accept="image/*" />
                                    </FormControl>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormControl>
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Profile Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                    )}
                                    </FormControl>
                                </GridItem> */}
                            </Grid>
                        </DrawerBody>
                        <DrawerFooter>
                            <Button variant='outline' mr={3} onClick={onContactFormClose}>
                                Cancel
                            </Button>
                            <Button isDisabled={isDisabled} colorScheme='blue' type='submit'>{isUpdate ? "Update" : "Create"}</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </form>
            </Drawer>
        </>
    );
};

export default UserForm;
