import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import { ChakraProvider } from "@chakra-ui/react";
import App from './App';

const container = document.getElementById('root');
if (container) {
    const root = ReactDOM.createRoot(container); // Create a root.
    root.render(
        <React.StrictMode>
            <ChakraProvider>
                <App />
            </ChakraProvider>
        </React.StrictMode>
    );
}
