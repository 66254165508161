// src/services/contactService.ts

import API from '../utils/api';

const fetchContacts = async (page: number, searchQuery: string = ""): Promise<any> => {
    try {
        console.log(page, "page");
        
        const response = await API.get<any>(`/api/contacts/list?page=${page}&search=${searchQuery}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch contacts';
        throw new Error(errorMessage);
    }
};

const fetchContactsPublic = async (page: number, searchQuery: string = ""): Promise<any> => {
    try {
        console.log(page, "page");
        
        const response = await API.get<any>(`/api/contacts/public?page=${page}&search=${searchQuery}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch contacts';
        throw new Error(errorMessage);
    }
};

const createContact = async (formData: FormData) => {
    try {
        const response = await API.post('/api/contacts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to create contact';
        throw new Error(errorMessage);
    }
};

const updateContact = async (id: string, formData: FormData): Promise<any> => {
    try {
        const response = await API.post(`/api/contacts/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to update contact';
        throw new Error(errorMessage);
    }
};

const getContact = async (id: string): Promise<any> => {
    try {
        const response = await API.get(`/api/contact/${id}`, {
            headers: {
                'Content-Type': 'application/json', // Change content type if needed
            },
        });
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to get contact';
        throw new Error(errorMessage);
    }
};

const deleteContact = async (id: number): Promise<void> => {
    try {
        await API.delete(`/api/contacts/${id}`);
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to delete contact';
        throw new Error(errorMessage);
    }
};


export const contactService = {
    fetchContacts,
    createContact,
    updateContact,
    deleteContact,
    getContact,
    fetchContactsPublic
};
