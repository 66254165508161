import { useState, useEffect } from 'react';
import { Button, Box, Flex, Spinner, useToast, SimpleGrid } from '@chakra-ui/react';
import { contactService } from '../../services/contactService';
import { useAppContext } from '../../context/AppContext';
import ProfileCard from './ProfileCard';
import Pagination from '../common/Pagination';
import DisplayForm from './DisplayForm';
// Define the type for each cimport { Badge } from '@chakra-ui/react'ontact
type Contact = {
    id: number;
    full_name: string;
    designation: string;
    mobile_no_01: string;
    office_no_01: string;
    email: string | null;
    address: string;
    contact_status: string;
};


const ContactCardList = () => {
    //const [contacts, setContacts] = useState<Contact[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const { dispatch, state, onContactDisplayFormOpen  } = useAppContext();
    const { contacts, } = state;
 
    const loadContacts = async (page: number, searchQuery = '') => {
        setLoading(true);

        try {
            const response = await contactService.fetchContactsPublic(page, searchQuery);
            dispatch({ type: 'SET_CONTACTS', payload: response.data });
            setCurrentPage(response.current_page);
            setTotalPages(response.last_page);
            setTotalRecords(response.total);
        } catch (err) {
            //setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    // Effect to fetch data on component mount and search change
    useEffect(() => {
        loadContacts(1, search);
    }, [search]);

    // Pagination handlers
    const handlePrevious = () => {
        if (currentPage > 1) loadContacts(currentPage - 1, search);
    };

    const handleNext = () => {
        loadContacts(currentPage + 1, search);
    };

    // Initial load
    useEffect(() => {
        loadContacts(currentPage);
    }, [currentPage]);

    const onRowSelect = async (data: any) => {
        const response = await contactService.getContact(data);
        dispatch({ type: 'SET_CONTACT', payload: response });
        onContactDisplayFormOpen();
    };

    const handlePageChange = (value: any) => {
        const page = parseInt(value, 10);
        if (!isNaN(page) && page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <Box>
            <Pagination
                search={search}
                setSearch={setSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
            />
            {loading ? (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            ) : (

            <SimpleGrid columns={[1, null, 3]} spacing='40px'>
                {contacts?.map((contact) => (
                    <ProfileCard
                        id={contact.id}
                        onClick={onRowSelect}  
                        title={contact.title}
                        key={contact.id}
                        fullName={contact.full_name}
                        mobileNumber={contact.mobile_no_01}
                        faxNumber={contact.fax_no_01}
                        email={contact.email}
                        designation={contact.designation}
                        profile_pic={contact.profile_picture}
                    />
                  
                ))}
            </SimpleGrid>
            )}
            <DisplayForm />
            <Flex mt={4}>
                <Button onClick={handlePrevious} disabled={currentPage <= 1} mr={2}>
                    Previous
                </Button>
                <Button onClick={handleNext} disabled={totalPages === currentPage}>
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default ContactCardList;