import React, { createContext, useReducer, useContext, ReactNode } from 'react';

const initState = {
    title: 'Mr',
    full_name: '',
    designation: '',
    company: '',
    mobile_no_01: '',
    mobile_no_02: '',
    whatsapp_no: '',
    office_no_01: '',
    office_no_02: '',
    fax_no_01: '',
    fax_no_02: '',
    email: '',
    official_email: '',
    address: '',
    contact_type: 'Person',
    contact_status: 'On Duty',
    profile_picture: null as File | null,
    description: '',
};
interface AppState {
    contacts: any[];
    contact: any;
    categories: any[];
    users: any[];
    user: any;
    isContactFormOpen: boolean; 
    isDisplayFormOpen: boolean;
    isUpdate: boolean; 
    
}

interface AppProviderProps {
    children: ReactNode;
}

const initialState: AppState = {
    contacts: [],
    contact: initState,
    categories: [],
    users: [],
    user: {},
    isContactFormOpen: false,
    isDisplayFormOpen: false,
    isUpdate: false,
};

interface AppContextType {
    state: AppState;
    dispatch: React.Dispatch<any>;
    onContactFormOpen: () => void; // No argument expected
    onContactFormClose: () => void; // No argument expected
    onContactFormEditOpen: () => void; // No argument expected
    onContactDisplayFormOpen: () => void;
    onContactDisplayFormOClose: () => void;
}

const AppContext = createContext<AppContextType>({
    state: initialState,
    dispatch: () => null,
    onContactFormOpen: () => null,
    onContactFormClose: () => null,
    onContactFormEditOpen: () => null,
    onContactDisplayFormOpen:  () => null,
    onContactDisplayFormOClose: () => null,
});

function appReducer(state: AppState, action: any): AppState {
    switch (action.type) {
        case 'SET_CONTACTS':
            return { ...state, contacts: action.payload };
        case 'SET_CONTACT':
            return { ...state, contact: action.payload };
        case 'SET_CATEGORIES':
            return { ...state, categories: action.payload };
        case 'SET_USERS':
            return { ...state, users: action.payload };
        case 'SET_USER':
            return { ...state, user: action.payload };
        case 'OPEN_CONTACT_FORM':
            return { ...state, isContactFormOpen: true, isUpdate: false };
        case 'OPEN_CONTACT_EDIT':
            return { ...state, isContactFormOpen: true, isUpdate: true };
        case 'CLOSE_CONTACT_FORM':
            return { ...state, isContactFormOpen: false, isUpdate: false };
        case 'OPEN_CONTACT_DISPLAY_FORM':
                return { ...state, isDisplayFormOpen: true };
        case 'CLOSE_CONTACT_DISPLAY_FORM':
                return { ...state, isDisplayFormOpen: false };
        default:
            return state;
    }
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState);

    const onContactFormOpen = () => dispatch({ type: 'OPEN_CONTACT_FORM' });
    const onContactFormClose = () => dispatch({ type: 'CLOSE_CONTACT_FORM' });
    const onContactFormEditOpen = () => dispatch({ type: 'OPEN_CONTACT_EDIT' });
    const onContactDisplayFormOpen = () => dispatch({ type: 'OPEN_CONTACT_DISPLAY_FORM' });
    const onContactDisplayFormOClose = () => dispatch({ type: 'CLOSE_CONTACT_DISPLAY_FORM' });

    return (
        <AppContext.Provider value={{ state, dispatch, onContactFormOpen, onContactFormClose, onContactFormEditOpen, onContactDisplayFormOpen, onContactDisplayFormOClose  }}>
            {children}
        </AppContext.Provider>
    );
};

export function useAppContext() {
    return useContext(AppContext);
}
