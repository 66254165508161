// src/services/categoryService.ts

import API from '../utils/api';
import { CategoryResponse } from '../types/types'; // Define this type based on your data structure

/**
 * Fetch top-level categories.
 */
const fetchCategories = async (): Promise<CategoryResponse[]> => {
    try {
        const response = await API.get<CategoryResponse[]>('/api/categories');
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch top-level categories';
        throw new Error(errorMessage);
    }
};

/**
 * Fetch subcategories based on the ID of a top-level category.
 * @param parentId The ID of the parent category.
 */
const fetchSubCategories = async (parentId: string): Promise<CategoryResponse[]> => {
    try {
        const response = await API.get<CategoryResponse[]>(`/api/categories/parent/${parentId}`);
        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Failed to fetch subcategories';
        throw new Error(errorMessage);
    }
};


export const categoryService = {
    fetchCategories,
    fetchSubCategories,
};
