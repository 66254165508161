/* eslint-disable react/jsx-no-undef */
import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react';
import ContactsTable from '../components/contacts/ContactsTable';
import ContactForm from '../components/contacts/ContactForm';

function Dashboard() {
  return (
      <Box minH="100vh">
        <Flex justifyContent="space-between" alignItems="center" mb={4} mx={10}>
            <Box>
                <Heading as="h1" size="xl" mb={1}>
                  Agri Staff Contact Directory
                </Heading>
                <Text fontSize="md" color="gray.600">
                    Telephone directory of the Department of Agriculture & Provincial Departments of Agriculture Sri Lanka
                </Text>
            </Box>
            <ContactForm />
        </Flex>
          <Box flex="1" p="10">
            <Box mt="5">
                <ContactsTable />
            </Box>
          </Box>
      </Box>
  );
}

export default Dashboard;
