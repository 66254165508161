import { ReactNode } from 'react';
import {
  Box,
  Flex,
  HStack,
  Heading,
  useColorModeValue,
  Text,
  Button
} from '@chakra-ui/react';
import { Logo } from '../components/common/Logo';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const PublicLayout: React.FC<DashboardLayoutProps> = ({ children }: any) => {
  return (
    <>
      <Box bg={useColorModeValue('#2DBB54', 'gray.100')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Box><Logo height="60px" width="60px" /></Box>
            <Box color="white" display={{ base: 'none', md: 'block' }}>
              <Heading as="h3" size="l" mb={1}>
                Agri Staff Contact Directory
              </Heading>
              <Text fontSize="sm">
                Telephone directory of the Department of Agriculture & Provincial Departments of Agriculture Sri Lanka
              </Text>
            </Box>
          </HStack>
          <Flex alignItems={'center'}>
            <Button
              variant={'outline'}
              _hover={{ bg: 'green.500', color: 'white' }}
            >
              <Text color='white'>Contact Us</Text>
            </Button>
          </Flex>
        </Flex>
      </Box>

      <Box p={4}> {children} </Box>

      <Box as="footer" position="fixed" bottom={0} width="100%" py={2} bg='white'>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Text fontSize="sm">
            (c) 2024 | Television and Farm Broadcasting Service | NAICC | DDASL
          </Text>
        </Flex>
      </Box>
    </>
  );
}

export default PublicLayout;
