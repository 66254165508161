import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    Box,
    Text,
    Flex,
    Image,
    Icon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { categoryService } from '../../services/categoryService';
import { useAppContext } from '../../context/AppContext';
import { BASE_API_URL } from '../../config/config';
import { FiPhone, FiMail, FiMapPin, FiBriefcase, FiFileText } from 'react-icons/fi';
import UserLogo from '../../assets/user-image.png';
interface ContactFormProps { }

const DisplayForm: React.FC<ContactFormProps> = () => {
    const { onContactDisplayFormOClose } = useAppContext();
    const { dispatch, state } = useAppContext();
    const { contact, isDisplayFormOpen } = state;

    const [imagePreview, setImagePreview] = useState<string | null>(null);

    useEffect(() => {
        if (contact?.profile_picture) {
            setImagePreview(`${BASE_API_URL}/storage/app/public/${contact?.profile_picture}`);
        } else {
            let type;

            switch (contact?.title) {
                case 'Mr':
                    type = 'boy';
                    break;
                case 'Mrs':
                case 'Miss':
                    type = 'girl';
                    break;
                default:
                    type = '';
                    break;
            }

            setImagePreview(UserLogo)
        }
    }, [contact]);

    const onClose = () => {
        setImagePreview(null);
        onContactDisplayFormOClose();
    };

    const renderField = (label: string, value: string, icon: JSX.Element) => {
        if (!value) return null;
        return (
            <Box mb={4}>
                <Flex align="center" color="green.500">
                    {icon}
                    <Text ml={2} fontWeight="bold">{label}</Text>
                </Flex>
                <Text ml={6}>{value}</Text>
            </Box>
        );
    };

    return (
        <Drawer
            isOpen={isDisplayFormOpen}
            placement='right'
            onClose={onClose}
            size="xl"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    View Contact Details
                </DrawerHeader>

                <DrawerBody>
                    <Flex direction="column" align="center" mb={6}>
                        {imagePreview && (
                            <Image borderRadius="full" boxSize="150px" src={imagePreview} alt="Profile Preview" mb={4} />
                        )}
                        <Text fontSize="xl" fontWeight="bold">{contact?.full_name}</Text>
                    </Flex>

                    <Box>
                        {renderField('Top Level Category', contact?.categories?.[0]?.name, <Icon as={FiBriefcase} />)}
                        {renderField('Second Level Category', contact?.categories?.[1]?.name, <Icon as={FiBriefcase} />)}
                        {renderField('Third Level Category', contact?.categories?.[2]?.name, <Icon as={FiBriefcase} />)}

                        
                        {renderField('Department', contact?.designation, <Icon as={FiBriefcase} />)}
                        {renderField('Mobile 1', contact?.mobile_no_01, <Icon as={FiPhone} />)}
                        {renderField('Mobile 2', contact?.mobile_no_02, <Icon as={FiPhone} />)}
                        {renderField('Office 1', contact?.office_no_01, <Icon as={FiPhone} />)}
                        {renderField('Office 2', contact?.office_no_02, <Icon as={FiPhone} />)}
                        {renderField('Fax', contact?.fax_no_01, <Icon as={FiPhone} />)}
                        {renderField('Email', contact?.email, <Icon as={FiMail} />)}
                        {renderField('Address', contact?.address, <Icon as={FiMapPin} />)}
                        {renderField('Description', contact?.description, <Icon as={FiFileText} />)}
                    </Box>
                </DrawerBody>
                <DrawerFooter>
                    <Button bg="red" color="white" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default DisplayForm;
