/* eslint-disable react/jsx-no-undef */
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react';

import UsersTable from '../components/contacts/UsersTable';
import UserForm from '../components/contacts/UserForm';

function Users() {
  return (
      <Box minH="100vh">
        <Flex justifyContent="space-between" alignItems="center" mb={4} mx={10}>
            <Box>
                <Heading as="h1" size="xl" mb={1}>
                  Users
                </Heading>
                <Text fontSize="md" color="gray.600">
                 Manage Users
                </Text>
            </Box>
            <UserForm />
        </Flex>
          <Box flex="1" p="10">
            <Box mt="5">
                <UsersTable />
            </Box>
          </Box>
      </Box>
  );
}

export default Users;
