import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    useToast,
} from '@chakra-ui/react'
import { Logo } from '../common/Logo'
import { PasswordField } from '../common/PasswordField'
import React, { useState } from "react";
import { authService } from '../../services/authService';
import { LoginCredentials } from '../../types/types';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';

interface Props {}

export const Login: React.FC<Props> = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const credentials: LoginCredentials = { email, password };

        try {
            const response = await authService.login(credentials);
            login(response); 
            toast({
                title: "Login successful",
                description: "You have successfully logged in.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            navigate('/dashboard'); 
        } catch (error) {
            toast({
                title: "Login failed",
                description: "An error occurred during login.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
            <Stack spacing="8">
                <Stack spacing="6" align={'center'}>
                    <Logo />
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
                    </Stack>
                </Stack>
                <Box
                    py={{ base: '0', sm: '8' }}
                    px={{ base: '4', sm: '10' }}
                    bg={{ base: 'transparent', sm: 'bg.surface' }}
                    boxShadow={{ base: 'none', sm: 'md' }}
                    borderRadius={{ base: 'none', sm: 'xl' }}
                >
                    <Stack spacing="6">
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>
                            <PasswordField onChange={(e) => setPassword(e.target.value)} />
                        </Stack>
                        <Stack spacing="6">
                            <Button onClick={() => handleSubmit()}>Sign in</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );
};
