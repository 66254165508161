import React from 'react';
import { Box, Text, Image, VStack } from '@chakra-ui/react';
import { BASE_API_URL } from '../../config/config';
import UserLogo from '../../assets/user-image.png';
interface ContactCardProps {
  id:number;
  fullName: string;
  designation: string;
  mobileNumber: string;
  faxNumber: string;
  email: string;
  title: string;
  profile_pic: string
  onClick: (id: number) => void;
}

const ProfileCard: React.FC<ContactCardProps> = ({ id, fullName, designation, mobileNumber, faxNumber, email, title, profile_pic, onClick }) => {

  let type;

  switch (title) {
    case 'Mr':
      type = 'boy';
      break;
    case 'Mrs':
    case 'Miss':
      type = 'girl';
      break;
    default:
      type = '';
      break;
  }

  return (
    <Box
      onClick={() => onClick(id)} 
      borderRadius="md"
      overflow="hidden"
      boxShadow="lg"
      maxW={{ base: '300px', md: 'md' }}
      mx="auto"
      bg="white"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      borderColor="gray.200"
      borderWidth="1px"
      h={{ base: 'auto', md: '300px' }}
    >
      <Box
        bgGradient="linear(to-b, green.300, green.500)"
        p={6}
        textAlign="center"
        w={{ base: '300px', md: '200px' }}
      >

        <Image
          borderRadius="full"
          boxSize="100px"
          src={profile_pic ? `${BASE_API_URL}/storage/app/public/${profile_pic}` : UserLogo}
          alt="Profile Image"
          mx="auto"
          mb={4}
        />
        <Text fontSize="lg" fontWeight="bold" color="white">{fullName}</Text>
        <Text fontSize="md" color="white">{designation}</Text>
      </Box>
      <Box p={6} flex="1" w={{ base: '100%', md: '250px' }}>
        <VStack align="start" spacing={4}>
          <Box>
            <Text fontSize="md" fontWeight="bold">Information</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.500">Email</Text>
            <Text fontSize="sm" noOfLines={1}>{email}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.500">Mobile Number</Text>
            <Text fontSize="sm">{mobileNumber}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.500">Fax Number</Text>
            <Text fontSize="sm">{faxNumber}</Text>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default ProfileCard;
