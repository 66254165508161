import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import AgriLogo from '../../assets/logo1.png';

interface LogoProps extends HTMLChakraProps<'img'> {
  height?: string | number;
  width?: string | number;
}

export const Logo = ({ height = 32, width = 32, ...props }: LogoProps) => (
  <chakra.img
    src={AgriLogo} // Use the imported image
    alt="Agri Logo" // Provide alt text for accessibility
    height={height}
    width={width}
    {...props}
  />
)