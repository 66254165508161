import { ReactNode } from 'react';
import {
  Box,
  Text,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Logo } from '../components/common/Logo';
import { authService } from '../services/authService';



const NavLink = ({ children, url }: { children: ReactNode, url: string }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('blue.200', 'blue.700'),
    }}
    href={url}>
    {children}
  </Link>
);

interface DashboardLayoutProps {
  children?: ReactNode; // Children can be optional
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Links = [
    { name: 'Home', url: '/' },
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Users', url: '/users' },
  ];
  
  const isAdmin =  localStorage.getItem('r') === 'admin';

  const filteredLinks = isAdmin ? Links : Links.filter(link => link.name !== 'Users');

  const userLogout = async () => {
    await authService.logout();
  }
  return (
    <>
      <Box bg={useColorModeValue('#2DBB54', 'gray.100')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box><Logo height="60px" width="60px" /></Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {filteredLinks.map((link) => (
                <NavLink key={link.url} url={link.url}>
                  {link.name}
                </NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                
                <Flex alignItems={'center'} gap={4}>
                  <Text color="white"> Hi, {localStorage.getItem('fullName')} </Text>

                  <Avatar
                    size={'sm'}
                    src={
                      `https://avatar.iran.liara.run/public`
                    }
                  />
                </Flex>
              
            
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => userLogout()}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link.url} url={link.url}>
                  {link.name}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Box p={4}> {children} </Box>
    </>
  );
}

export default DashboardLayout;