import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Box,
    Flex,
    Spinner,
    useToast,
    IconButton,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay
} from '@chakra-ui/react';
import { useAppContext } from '../../context/AppContext';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import Pagination from '../common/Pagination';
import { usersService } from '../../services/userService';

const UsersTable = () => {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const { dispatch, state, onContactFormEditOpen } = useAppContext();
    const { users } = state;
    const toast = useToast();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [contactToDelete, setContactToDelete] = useState<number | null>(null);
    const cancelRef = useRef<HTMLButtonElement>(null);

    const loadContacts = async (page: number, searchQuery = '') => {
        setLoading(true);

        try {
            const response = await usersService.fetchUsers(page, searchQuery);
            dispatch({ type: 'SET_USERS', payload: response.data });
            setCurrentPage(response.current_page);
            setTotalPages(response.last_page);
            setTotalRecords(response.total);
        } catch (err) {
            console.log(err);
            toast({
                title: 'Error',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadContacts(1, search);
    }, [search]);

    const handlePrevious = () => {
        if (currentPage > 1) loadContacts(currentPage - 1, search);
    };

    const handleNext = () => {
        loadContacts(currentPage + 1, search);
    };

    // Initial load
    useEffect(() => {
        loadContacts(currentPage);
    }, [currentPage]);

    const onRowSelect = async (data: any) => {
        const response = await usersService.getUser(data.id);
        dispatch({ type: 'SET_USER', payload: response });
        onContactFormEditOpen();
    };

    const handlePageChange = (value: any) => {
        const page = parseInt(value, 10);
        if (!isNaN(page) && page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleDeleteContact = async (id: number) => {
        try {
            await usersService.deleteUser(id);
            toast({
                title: 'Contact deleted successfully',
                description: 'You have successfully deleted a contact.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            loadContacts(currentPage, search); // Reload contacts after deletion
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error deleting contact',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsDeleteDialogOpen(false); // Close the dialog after the operation
        }
    };

    const confirmDeleteContact = (id: number) => {
        setContactToDelete(id);
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setContactToDelete(null);
        setIsDeleteDialogOpen(false);
    };

    return (
        <Box>
            <Pagination
                search={search}
                setSearch={setSearch}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                handlePageChange={handlePageChange}
            />
            {loading ? (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            ) : (
                <Table variant="simple">
                    <Thead>
                        <Tr bg="#96dda9">
                            <Th>Name</Th>
                            <Th>Mobile</Th>
                            <Th>Email</Th>
                            <Th>Category 1</Th>
                            <Th>Category 2</Th>
                            <Th>Category 3</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users?.map((users) => (
                            <Tr key={users.id}>
                                <Td>{users.full_name}</Td>
                                <Td>{users.phone_no}</Td>
                                <Td>{users.email}</Td>
                                <Td>{users.categories[0]?.name}</Td>
                                <Td>{users.categories[1]?.name}</Td>
                                <Td>{users.categories[2]?.name}</Td>
                                <Td>
                                    <Flex alignItems="center" gap={5}>
                                        <IconButton
                                            icon={<EditIcon />}
                                            colorScheme="green"
                                            aria-label="Update user"
                                            onClick={() => onRowSelect(users)}
                                            ml={4}
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            colorScheme="red"
                                            aria-label="Delete User"
                                            onClick={() => confirmDeleteContact(users.id)}
                                        />
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
            <Flex mt={4}>
                <Button onClick={handlePrevious} disabled={currentPage <= 1} mr={2}>
                    Previous
                </Button>
                <Button onClick={handleNext} disabled={totalPages === currentPage}>
                    Next
                </Button>
            </Flex>

            <AlertDialog
                isOpen={isDeleteDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={closeDeleteDialog}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete User
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete this user? This action cannot be undone.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeDeleteDialog}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => handleDeleteContact(contactToDelete!)} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default UsersTable;
